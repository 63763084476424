









































































































import Vue from 'vue';
import firebase from 'firebase/app';
import 'firebase/storage';
import { db, storage } from '@/main';
import { TeamMember } from '@/models/teamMember.model';

export default Vue.extend({
  name: 'EditTeamMember',
  data() {
    return {
      isBusy: false,
      teamMember: {} as TeamMember,
      rules: {
        required: (value: string) => !!value || 'Required.',
        descriptionLength: (value: string) =>
          value?.length <= 300 || 'Max 300 characters'
      },
      isTeamMemberFormValid: false,
      snackbar: false,
      snackbarText: '',
      snackbarColor: '',
      uploadProgress: 0,
      teamMemberImage: undefined,
      teamMemberImageUrl: undefined
    };
  },
  methods: {
    getData() {
      if (this.$route.query.teamMemberUid) {
        db.collection('practices')
          .doc(this.$store.state.currentUser.activePractice.uid)
          .collection('teamMembers')
          .doc(this.$route.query.teamMemberUid as string)
          .get()
          .then(async (doc: firebase.firestore.DocumentData) => {
            this.teamMember = doc.data() as TeamMember;
            if (this.teamMember.imageRef) {
              const ref = storage.ref(this.teamMember.imageRef);
              this.teamMemberImageUrl = await ref.getDownloadURL();
            }
          });
      } else {
        this.teamMember = {
          uid: db.collection('practices').doc().id,
          name: '',
          title: '',
          surname: '',
          imageRef: '',
          about: '',
          whyWorkHere: ''
        };
      }
    },
    updateData() {
      db.collection('practices')
        .doc(this.$store.state.currentUser.activePractice.uid)
        .collection('teamMembers')
        .doc(this.teamMember.uid)
        .set(this.teamMember, { merge: true })
        .then(() => {
          this.showSnackbar('Team member updated.', 'success');
          this.$router.push({
            name: 'PracticeProfile',
            query: { activeTab: '3' }
          });
        })
        .catch((error: Error) => {
          this.showSnackbar(error.message, 'error');
          console.error(error);
        });
    },
    launchTeamMemberImageUpload() {
      (this.$refs.teamMemberImageInput as any).$refs.input.click();
    },
    async onTeamMemberImageUpload() {
      this.uploadProgress = 1;
      try {
        const ref = storage.ref(`practice/teamMembers/${this.teamMember.uid}`);
        const task = ref.put((this.teamMemberImage as unknown) as Blob);
        task.on('state_changed', (snapshot: any) => {
          this.uploadProgress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        });
        await task
          .then(async () => {
            this.teamMemberImageUrl = await ref.getDownloadURL();
            this.uploadProgress = 0;
          })
          .catch((error: Error) => {
            this.uploadProgress = 0;
            console.error(error);
          });
        this.teamMember.imageRef = `practice/teamMembers/${this.teamMember.uid}_500x500`;
        this.showSnackbar('Image uploaded.', 'success');
      } catch (error) {
        console.error(error);
      }
    },
    showSnackbar(text: string, color: string) {
      this.snackbarText = text;
      this.snackbarColor = color;
      this.snackbar = true;
    }
  },
  created() {
    this.getData();
    window.scrollTo(0, 0);
  }
});
